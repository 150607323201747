import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import options from "../../../richText/richTextOptions";
import CTAButton from "../../common/CTAButton";
import { colors } from "../../../vars/palette";
import { RichText, RichTextReact } from "wmk-rich-text";
import { Img, WmkImage } from "wmk-image";

const desktopMinHeight = "25rem";
const mobileMinHeight = "300px";

const WrapCont = styled(Container)`
  padding: 0;
  min-height: ${desktopMinHeight};
  position: relative;
  .row {
    width: 100%;
  }
  .content-col {
    padding: 0;
  }
  .image-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    min-height: ${desktopMinHeight};
    .gatsby-image-wrapper {
      width: 100vw !important;
      height: 100% !important;
      min-height: ${desktopMinHeight};
    }
  }
  .content-wrap {
    min-height: ${desktopMinHeight};
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background: ${colors.rgba("black", 0.88)};
    color: ${colors.hex("white")};
    padding: 2rem 2rem 4rem 10vw;
  }
  @media only screen and (max-width: 991px) {
    margin-top: ${mobileMinHeight};
    .content-col {
      position: relative;
      right: -15px;
    }
    .image-wrap {
      position: absolute;
      top: -${mobileMinHeight};
      .gatsby-image-wrapper {
        height: ${mobileMinHeight} !important;
        min-height: ${mobileMinHeight} !important;
        width: 100% !important;
      }
    }
  }
`;

const TopicBottomSection = ({
  image,
  copy,
}: {
  image: Img;
  copy: RichText;
}) => {
  // const { fixed } = image ? image : null;
  return (
    <WrapCont fluid>
      <Row>
        <Col md={12} lg={7} className="content-col">
          <div className="image-wrap">
            <WmkImage image={image} />
            {/* <Img fixed={fixed} alt={image.title} /> */}
          </div>
          <div className="content-wrap">
            <RichTextReact content={copy} options={options} />
            {/* {documentToReactComponents(copy.json, options)} */}
            <CTAButton />
          </div>
        </Col>
        <Col md={12} lg={5}></Col>
      </Row>
    </WrapCont>
  );
};

export default TopicBottomSection;
