import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import options from "../../../richText/richTextOptions";
import IconText from "../Home/IconText";
import { colors } from "../../../vars/palette";
import { RichText, RichTextReact } from "wmk-rich-text";

const Wrap = styled.div`
  display: flex;
  padding: 2rem 10vw 2rem 0;
  &.actions {
    padding: 2rem;
  }
  .callout {
    // margin-right: 2rem;
    &.enviro {
      width: 75vw;
      min-width: 800px;
    }
    .co-line-1 {
      white-space: nowrap;
      padding: 10px 5vw 10px 10vw;
      margin-bottom: 0.75rem;
      background: ${colors.rgba("black", 0.88)};
      color: ${colors.hex("white")};
      font-size: 1.75rem;
      font-weight: 300;
      word-spacing: 0.2rem;
      clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
      p {
        margin-bottom: 0;
        span {
          color: ${colors.hex("secondary")};
          font-weight: 500;
        }
      }
    }
    p.co-line-2 {
      font-weight: 600;
      padding: 0 5vw 0 10vw !important;
      line-height: 2rem;
    }
  }
  @media only screen and (min-width: 1200px) {
    .enviro {
      .co-line-1 {
        padding: 10px 5vw 10px 20vw !important;
      }
      p.co-line-2 {
        padding: 0 5vw 0 20vw !important;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .callout {
      padding: 0 10vw;
    }
    .co-line-1 {
      background: none !important;
      clip-path: none !important;
      color: ${colors.hex("secondary")} !important;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 0 !important;
      margin-top: 2rem;
      padding: 0 5vw !important;
      white-space: normal !important;
      p {
        margin-bottom: 0;
        span {
        }
      }
    }
    .co-line-2 {
      font-size: 1.25rem !important;
      font-weight: 600;
      text-align: center;
    }
    .enviro {
      width: 100vw !important;
      min-width: 100vw !important;
      p.co-line-2 {
        width: 80vw;
        text-align: center;
        font-size: 1.25rem !important;
        padding: 0 5vw !important;
      }
    }
    .copy-wrap {
      text-align: center;
      padding: 1rem 3rem;
      min-width: 50vw;
    }
  }
`;

const TopicMidSec = ({
  callout,
  copy,
  slug,
}: {
  callout: { callout: string };
  copy: RichText;
  slug: string;
}) => {
  const calloutCopy = callout && callout.callout;
  const calloutHighlited =
    calloutCopy &&
    calloutCopy.substring(
      calloutCopy.indexOf("__") + 2,
      calloutCopy.lastIndexOf("__")
    );
  const calloutLine1 =
    calloutCopy &&
    calloutCopy.substring(
      calloutCopy.lastIndexOf("__") + 2,
      calloutCopy.lastIndexOf("<")
    );
  const calloutLine2 =
    calloutCopy && calloutCopy.substring(calloutCopy.lastIndexOf("<") + 1);

  const enviro =
    slug === "environment-extends-beyond-the-workplace" ? true : false;
  const actions = slug === "actions-speak-louder-than-words" ? true : false;

  interface actionIconText {
    title: string;
    icon: {
      file: {
        url: string;
        filename: string;
      };
    };
    sortOrder: number;
    text: {
      copy: string;
    };
  }

  const actionsIconText = useStaticQuery(graphql`
    query {
      allContentfulIconText(
        filter: { title: { regex: "/Actions/" } }
        sort: { order: ASC, fields: sortOrder }
      ) {
        edges {
          node {
            title
            icon {
              file {
                url
              }
            }
            sortOrder
            text {
              copy: text
            }
          }
        }
      }
    }
  `).allContentfulIconText.edges.map((p: { node: actionIconText }) => p.node);

  return (
    <Wrap className={actions ? "actions" : ""}>
      {actions ? (
        <Container>
          <Row>
            {actionsIconText.map((piece: actionIconText, i: number) => {
              // console.log("piece from actions: ", piece);
              return (
                <Col
                  key={"piece" + i}
                  sm={12}
                  md={4}
                  style={{ marginBottom: "2rem" }}
                >
                  <IconText icon={piece.icon} text={piece.text} />
                </Col>
              );
            })}
          </Row>
        </Container>
      ) : (
        <>
          <div className={enviro ? "callout enviro" : "callout"}>
            <div className="co-line-1">
              <p>
                <span>{calloutHighlited}</span> {` ` + calloutLine1}
              </p>
            </div>
            <p className="co-line-2">{calloutLine2}</p>
          </div>
          <div className="copy-wrap">
            {copy && (
              <div className="copy">
                <RichTextReact content={copy} options={options} />
                {/* {documentToReactComponents(copy.json, options)} */}
              </div>
            )}
          </div>
        </>
      )}
    </Wrap>
  );
};

export default TopicMidSec;
