import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import TopSec from "../components/routes/Topics/TopSec";
import MidSec from "../components/routes/Topics/MidSec";
import BotSec from "../components/routes/Topics/BotSec";
import BackToTop from "../components/common/BackToTop";
import {
  HubUserContext,
  isValidUser,
} from "../components/context/HubspotContext";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import Layout from "../components/layout/MainLayout";
import { WmkSeo } from "wmk-seo";
import { TopicsQuery } from "../fragments/NodeTopicsFields";
import { RichText } from "wmk-rich-text";
import { Img, WmkImage } from "wmk-image";

const TopicNode = ({ data }: { data: { topic: TopicsQuery } }) => {
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true);
    }
  }, []);

  const mainCopy = new RichText(data.topic.mainCopy);
  const middleCopy = new RichText(data.topic.middleCopy);
  const endCopy = new RichText(data.topic.endCopy);
  const mainImage = new Img(data.topic.mainImage);
  const secondaryImage = new Img(data.topic.secondaryImage);
  return (
    <Layout>
      {/* <HubUserContext.Consumer>
        {(values) => {
          const { user } = values ? values : { user: null };
          //console.log(data); */}
          {validUser ? (
            <>
              <WmkSeo.Meta
                title={data?.topic?.title}
                path={"/"}
                slug={data?.topic?.slug}
                //description={description}
                ogImageUrl={""}
                twitterImageUrl={""}
                twitterHandle={twitter}
                siteTitle={siteTitle}
                baseUrl={baseUrl}
              />
              <TopSec
                title={data.topic.title}
                copy={mainCopy}
                image={mainImage}
                slug={data.topic.slug}
              />
              <MidSec
                callout={data.topic.callout}
                copy={middleCopy}
                slug={data.topic.slug}
              />
              <BotSec image={secondaryImage} copy={endCopy} />
              <BackToTop topic />
            </>
          ) : (
            <h3>
              Please go to the <a href="/">home page (esg-reports.com)</a> and
              fill out the form to access this free content.
            </h3>
          )}
        {/* }}
      </HubUserContext.Consumer> */}
    </Layout>
  );
};

export const query = graphql`
  query topicQuery($slug: String) {
    topic: contentfulTopics(slug: { eq: $slug }) {
      ...NodeTopicsFields
    }
  }
`;

export default TopicNode;
