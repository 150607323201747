import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { colors, WMKGradient } from "../../vars/palette";

const StyledBackText = styled.button`
  @media screen {
    :focus {
      outline: none;
    }
  }
  appearance: none;
  background: none;
  border: none;
  color: ${colors.hex("white")};
  position: relative;
  font-weight: 200;
  :hover {
    text-decoration: none;
  }
  p {
    font-size: 3rem;
    font-weight: 100;
    display: inline-block;
    margin-bottom: 0;
    position: absolute;
    top: -0.9rem;
    right: -1.5rem;
    transform: scaleX(1.2);
  }
`;

const Wrap = styled.div`
  position: fixed;
  z-index: 998;
  bottom: 0;
  width: 100%;
  ${WMKGradient.grad()}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  display: none;
  &.show {
    display: flex;
  }
  @media only screen and (min-width: 768px) {
    &.topic {
      display: none;
    }
  }
  @media only screen and (max-width: 767px) {
    background-image: none;
    background: ${colors.hex("text")};
  }
`;

const goBackToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

const BackToTop = ({ topic }: {topic?: boolean}) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const scroll = scrollPos;
    setShow(scroll >= window.innerHeight);
  }, [scrollPos]);

  useScrollPosition(({ currPos }) => {
    setScrollPos(-currPos.y);
  });

  return (
    <Wrap
      className={
        show && topic ? "show topic" : show ? "show" : topic ? "topic" : ""
      }
    >
      <StyledBackText onClick={goBackToTop}>
        Back to top <p>^</p>
      </StyledBackText>
    </Wrap>
  );
};

export default BackToTop;
