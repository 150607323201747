import React from "react";
import styled from "styled-components";
// import Theme from '../../../vars/ThemeOptions'
// import Img from 'gatsby-image'

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10%;
  h4 {
    font-weight: bold;
    margin: 1rem 0;
  }
`;

const IconText = ({
  icon,
  title,
  text,
}: {
  icon: { file: { url: string; filename?: string } };
  title?: string;
  text: { copy: string } | { text: string };
}) => {
  const { file } = icon ? icon : null;
  return (
    <Wrap>
      <img src={file.url} alt={file.filename ? file.filename : title ? title : "icon"} />
      <h4>{title && title + `:`}</h4>
      <p>
        {text && "copy" in text ? text.copy : "text" in text ? text.text : ""}
      </p>
    </Wrap>
  );
};

export default IconText;
