import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import options from "../../../richText/richTextOptions";
import { colors } from "../../../vars/palette";
import { RichText, RichTextReact } from "wmk-rich-text";
import { Img, WmkImage } from "wmk-image";

const WrapCont = styled(Container)`
  padding: 0;
  .row {
    height: 100%;
    width: 100%;
  }
  .gatsby-image-wrapper {
    width: 100vw !important;
    height: 455px;
  }
  .title-copy-col {
    background: ${colors.rgba("black", 0.88)};
    color: ${colors.hex("white")};
    position: relative;
    // top: -6px;
    right: -15px;
    .title {
      padding: 2rem;
      border-bottom: 2px solid ${colors.hex("secondary")};
      h1 {
        font-size: 2rem;
        font-weight: 300;
      }
    }
    .copy {
      padding: 2rem 8vw 2rem 2rem;
      p {
        font-weight: 300;
      }
      &.transparency {
        padding: 2rem 4vw 2rem 2rem;
        p {
          font-size: 0.85rem;
        }
      }
    }
  }
  @media only screen and (min-width: 1500px) {
    .transparency {
      p {
        font-size: 1rem !important;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    row {
      height: auto !important;
    }
    .title-copy-col {
      top: 0;
    }
    .img-col {
      height: 250px;
      .gatsby-image-wrapper {
        height: 250px !important;
      }
    }
    .title-copy-col {
      text-align: center;
      .copy {
        padding: 2rem 10vw;
        p {
          line-height: 1.75rem;
        }
      }
    }
  }
`;

const TopicTopSec = ({
  title,
  copy,
  image,
  slug,
}: {
  title: string;
  copy: RichText;
  image: Img;
  slug: string;
}) => {
  // const { fixed } = image ? image : null;
  const transparency =
    slug === "your-esg-report-its-all-about-transparency" ? true : false;
  return (
    <WrapCont fluid id="hero">
      <Row>
        <Col md={12} lg={7} className="img-col" style={{ padding: "0" }}>
          <WmkImage image={image} />
          {/* <Img fixed={fixed} alt={image.title} /> */}
        </Col>
        <Col md={12} lg={5} className="title-copy-col" style={{ padding: "0" }}>
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className={transparency ? "copy transparency" : "copy"}>
          <RichTextReact content={copy} options={options} />
            {/* {documentToReactComponents(copy.json, options)} */}
          </div>
        </Col>
      </Row>
    </WrapCont>
  );
};

export default TopicTopSec;
